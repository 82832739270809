import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import styled from "styled-components";
import Header from "./header/Header";
import Aside from "./Aside/Aside";
import Error from "./Error/Error";
import { useToggleMenu } from "../common/store";
import Login from "../Login";

const RoutingOnboardingBjs = lazy(() =>
  import("@bjsdistribution/tms-supplier-onboarding").then((module) => ({
    default: module.RoutingOnboardingBjs,
  }))
);

const RoutingDefectsBjs = lazy(() =>
  import("@bjsdistribution/tms-supplier-vehicle-maintenance").then(
    (module) => ({
      default: module.RoutingDefectsBjs,
    })
  )
);

const RoutingBreakdownBjs = lazy(() =>
  import("@bjsdistribution/tms-supplier-vehicle-maintenance").then(
    (module) => ({
      default: module.RoutingBreakdownBjs,
    })
  )
);

const RoutingChecksBjs = lazy(() =>
  import("@bjsdistribution/tms-supplier-vehicle-maintenance").then(
    (module) => ({
      default: module.RoutingChecksBjs,
    })
  )
);

const RoutingMaintenance = lazy(() =>
  import("@bjsdistribution/tms-supplier-vehicle-maintenance").then(
    (module) => ({
      default: module.RoutingMaintenance,
    })
  )
);
<Route path="*" element={<Error />} />;

function AppRouter() {
  const [toogleStatus, setToggleStatus] = useToggleMenu();
  const LoadingMessage = () => <div>Loading...</div>;
  return (
    <Router>
      <Login />
      <MainContainer className={toogleStatus ? "activeMenu" : ""}>
        <Aside />
        <div className="dashboard">
          <Header />
          <div className="placeHolder">
            <Suspense fallback={<LoadingMessage />}>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/onboarding/requested" replace />}
                />
                  <Route
                  path="/onboarding"
                  element={<Navigate to="/onboarding/requested" replace />}
                />
                <Route
                  path="/onboarding/*"
                  element={<RoutingOnboardingBjs />}
                />
                <Route
                  path="/maintenance"
                  element={<Navigate to="/maintenance/maintain" replace />}
                />
                <Route path="/maintenance/*" element={<RoutingMaintenance />} />

                <Route
                  path="/maintenance/vehicle-defects"
                  element={
                    <Navigate
                      to="/maintenance/vehicle-defects/new-defects"
                      replace
                    />
                  }
                />
                <Route
                  path="/maintenance/vehicle-defects/*"
                  element={<RoutingDefectsBjs />}
                />
                <Route
                  path="/maintenance/vehicle-checks/*"
                  element={<RoutingChecksBjs />}
                />
                <Route
                  path="/maintenance/breakdowns/*"
                  element={<RoutingBreakdownBjs />}
                />

                <Route path="*" element={<Error />} />
                {/* Add more routes as needed */}
              </Routes>
            </Suspense>
          </div>
        </div>
      </MainContainer>
    </Router>
  );
}

const MainContainer = styled.div`
  display: flex;

  .dashboard {
    flex: 1;
    background: ${({ theme }) => theme.secondaryWhite};
    transition: all ease 0.5s;
    .placeHolder {
      padding: 25px 20px 10px 20px;
    }
  }
`;

export default AppRouter;
