import React, { useEffect } from "react";
import useLogin from "./hooks/useLogin";
import useSsoAuth from "./hooks/useSsoAuth";
import { useLoading, useToken, useUserDetails } from "./common/store";
import {
  getCookie,
  getUrlParams,
  removeCookie,
  setCookie,
} from "../utils/login";

const ID_TOKEN = "id_token";

function Login() {
  const [token, setToken] = useToken();
  const beginLogin = useLogin();
  const ssoAuth = useSsoAuth();
  const [, setUserDetails] = useUserDetails();
  const [loading, setLoading] = useLoading();
  const hash = window.location.hash.substring(1);

  const beginLoginProcess = async () => {
    removeCookie(ID_TOKEN);
    const apiUrl = await beginLogin();
    if (apiUrl) {
      window.location.href = apiUrl;
    } else {
      console.error("Error while login");
    }
  };

  const startAuthenticationProcess = async () => {
    if (token) {
      const userData = await ssoAuth();
      if (userData.username) {
        setUserDetails(userData.username);
        setLoading(false);
        // Navigate(location.pathname);
      } else {
        console.error("Invalid user, trying to login again");
        beginLoginProcess();
      }
    }
  };

  const SignIn = async () => {
    const existingToken = await getCookie(ID_TOKEN);
    if (existingToken) {
      setToken(existingToken);
      startAuthenticationProcess();
    } else {
      const params = getUrlParams(hash);
      const idToken = params.get(ID_TOKEN);
      if (idToken) {
        setCookie(ID_TOKEN, idToken);
        setToken(idToken);
        setLoading(false);
      } else {
        beginLoginProcess();
      }
    }
  };

  // useEffect(() => {
  //   SignIn();
  // }, []);

  // useEffect(() => {
  //   startAuthenticationProcess();
  // }, [token]);

  return <div></div>;
}

export default Login;
