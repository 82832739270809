export const menuItems = [
  {
    id: 2,
    name: "Onboarding",
    iconName: "onboarding",
    url: "/onboarding",
  },
  {
    id: "M6",
    name: "Finance",
    iconName: "finance",
    url: "/abc",
  },
  {
    id: "M5",
    name: "Maintenance",
    iconName: "maintenance",
    url: "/maintenance",
    subMenu: [
      {
        name: "Maintenance",
        url: "maintenance/maintain",
      },
      {
        name: "Defect’s",
        url: "/maintenance/vehicle-defects",
      },
      
      {
        name: "Breakdown's",
        url: "/maintenance/breakdowns",
      },

    ],
  },
  {
    id: "M8",
    name: "Assets",
    iconName: "assets",
    url: "/assets",
  },

  
];
